export const starsBackground = `250px 557px 0 0px rgba(255, 255, 255, 0.77),
757px 487px 0 0px rgba(255, 255, 255, 0.943),
1656px 1149px 0 0px rgba(255, 255, 255, 0.532),
496px 939px 0 0px rgba(255, 255, 255, 0.672),
614px 1750px 0 0px rgba(255, 255, 255, 0.961),
713px 1434px 0 0px rgba(255, 255, 255, 0.013),
315px 501px 0 0px rgba(255, 255, 255, 0.777),
1145px 753px 0 0px rgba(255, 255, 255, 0.328),
1712px 255px 0 0px rgba(255, 255, 255, 0.449),
466px 206px 0 0px rgba(255, 255, 255, 0.012),
773px 977px 0 0px rgba(255, 255, 255, 0.937),
486px 69px 0 0px rgba(255, 255, 255, 0.955),
1550px 1297px 0 0px rgba(255, 255, 255, 0.222),
1653px 1527px 0 0px rgba(255, 255, 255, 0.208),
75px 35px 0 0px rgba(255, 255, 255, 0.785),
991px 1189px 0 0px rgba(255, 255, 255, 0.35),
1230px 569px 0 0px rgba(255, 255, 255, 0.445),
1091px 1437px 0 0px rgba(255, 255, 255, 0.747),
931px 831px 0 0px rgba(255, 255, 255, 0.51),
736px 1016px 0 0px rgba(255, 255, 255, 0.948),
90px 316px 0 0px rgba(255, 255, 255, 0.525),
893px 1606px 0 0px rgba(255, 255, 255, 0.506),
1778px 582px 0 0px rgba(255, 255, 255, 0.409),
1382px 1389px 0 0px rgba(255, 255, 255, 0.402),
136px 624px 0 0px rgba(255, 255, 255, 0.812),
698px 682px 0 0px rgba(255, 255, 255, 0.939),
451px 1116px 0 0px rgba(255, 255, 255, 0.13),
996px 257px 0 0px rgba(255, 255, 255, 0.443),
1483px 82px 0 0px rgba(255, 255, 255, 0.096),
1008px 83px 0 0px rgba(255, 255, 255, 0.773),
914px 722px 0 0px rgba(255, 255, 255, 0.031),
792px 681px 0 0px rgba(255, 255, 255, 0.686),
1303px 1428px 0 0px rgba(255, 255, 255, 0.889),
1424px 1267px 0 0px rgba(255, 255, 255, 0.697),
1633px 1208px 0 0px rgba(255, 255, 255, 0.019),
444px 1351px 0 0px rgba(255, 255, 255, 0.015),
292px 1045px 0 0px rgba(255, 255, 255, 0.666),
1395px 152px 0 0px rgba(255, 255, 255, 0.675),
684px 631px 0 0px rgba(255, 255, 255, 0.445),
932px 202px 0 0px rgba(255, 255, 255, 0.913),
1749px 878px 0 0px rgba(255, 255, 255, 0.863),
940px 460px 0 0px rgba(255, 255, 255, 0.974),
1389px 805px 0 0px rgba(255, 255, 255, 0.457),
1443px 385px 0 0px rgba(255, 255, 255, 0.854),
479px 1268px 0 0px rgba(255, 255, 255, 0.603),
1040px 986px 0 0px rgba(255, 255, 255, 0.468),
952px 1137px 0 0px rgba(255, 255, 255, 0.485),
399px 1726px 0 0px rgba(255, 255, 255, 0.429),
677px 1707px 0 0px rgba(255, 255, 255, 0.266),
345px 1548px 0 0px rgba(255, 255, 255, 0.677),
178px 793px 0 0px rgba(255, 255, 255, 0.176),
323px 989px 0 0px rgba(255, 255, 255, 0.629),
1610px 1192px 0 0px rgba(255, 255, 255, 0.811),
315px 1370px 0 0px rgba(255, 255, 255, 0.486),
568px 174px 0 0px rgba(255, 255, 255, 0.835),
369px 245px 0 0px rgba(255, 255, 255, 0.088),
1129px 1362px 0 0px rgba(255, 255, 255, 0.538),
1630px 1249px 0 0px rgba(255, 255, 255, 0.806),
681px 133px 0 0px rgba(255, 255, 255, 0.928),
548px 717px 0 0px rgba(255, 255, 255, 0.564),
1170px 833px 0 0px rgba(255, 255, 255, 0.527),
1329px 1111px 0 0px rgba(255, 255, 255, 0.701),
150px 1118px 0 0px rgba(255, 255, 255, 0.507),
65px 1009px 0 0px rgba(255, 255, 255, 0.641),
1765px 130px 0 0px rgba(255, 255, 255, 0.608),
1160px 1367px 0 0px rgba(255, 255, 255, 0.037),
537px 500px 0 0px rgba(255, 255, 255, 0.3),
1475px 1706px 0 0px rgba(255, 255, 255, 0.535),
367px 1685px 0 0px rgba(255, 255, 255, 0.533),
62px 769px 0 0px rgba(255, 255, 255, 0.68),
806px 1547px 0 0px rgba(255, 255, 255, 0.376),
860px 1382px 0 0px rgba(255, 255, 255, 0.25),
1244px 777px 0 0px rgba(255, 255, 255, 0.187),
154px 765px 0 0px rgba(255, 255, 255, 0.538),
915px 416px 0 0px rgba(255, 255, 255, 0.559),
75px 1253px 0 0px rgba(255, 255, 255, 0.374),
1247px 755px 0 0px rgba(255, 255, 255, 0.189),
1004px 273px 0 0px rgba(255, 255, 255, 0.315),
1767px 376px 0 0px rgba(255, 255, 255, 0.728),
300px 379px 0 0px rgba(255, 255, 255, 0.851),
774px 410px 0 0px rgba(255, 255, 255, 0.741),
787px 1048px 0 0px rgba(255, 255, 255, 0.625),
1313px 1597px 0 0px rgba(255, 255, 255, 0.752),
1547px 187px 0 0px rgba(255, 255, 255, 0.704),
1085px 1440px 0 0px rgba(255, 255, 255, 0.812),
1382px 1595px 0 0px rgba(255, 255, 255, 0.302),
1111px 1331px 0 0px rgba(255, 255, 255, 0.352),
1528px 173px 0 0px rgba(255, 255, 255, 0.343),
791px 671px 0 0px rgba(255, 255, 255, 0.505),
1378px 831px 0 0px rgba(255, 255, 255, 0.987),
1758px 910px 0 0px rgba(255, 255, 255, 0.793),
1501px 105px 0 0px rgba(255, 255, 255, 0.435),
468px 1029px 0 0px rgba(255, 255, 255, 0.679),
768px 675px 0 0px rgba(255, 255, 255, 0.336),
963px 794px 0 0px rgba(255, 255, 255, 0.723),
270px 457px 0 0px rgba(255, 255, 255, 0.958),
301px 1396px 0 0px rgba(255, 255, 255, 0.413),
109px 101px 0 0px rgba(255, 255, 255, 0.034),
505px 277px 0 0px rgba(255, 255, 255, 0.276),
1791px 191px 0 0px rgba(255, 255, 255, 0.904),
1429px 201px 0 0px rgba(255, 255, 255, 0.838),
467px 619px 0 0px rgba(255, 255, 255, 0.24),
1332px 67px 0 0px rgba(255, 255, 255, 0.161),
1665px 744px 0 0px rgba(255, 255, 255, 0.436),
162px 1645px 0 0px rgba(255, 255, 255, 0.927),
1326px 830px 0 0px rgba(255, 255, 255, 0.959),
551px 464px 0 0px rgba(255, 255, 255, 0.609),
1575px 1354px 0 0px rgba(255, 255, 255, 0.479),
1393px 907px 0 0px rgba(255, 255, 255, 0.623),
979px 161px 0 0px rgba(255, 255, 255, 0.3),
784px 1687px 0 0px rgba(255, 255, 255, 0.962),
731px 1483px 0 0px rgba(255, 255, 255, 0.299),
305px 579px 0 0px rgba(255, 255, 255, 0.664),
1590px 868px 0 0px rgba(255, 255, 255, 0.989),
34px 354px 0 0px rgba(255, 255, 255, 0.196),
1310px 539px 0 0px rgba(255, 255, 255, 0.007),
808px 309px 0 0px rgba(255, 255, 255, 0.344),
1061px 946px 0 0px rgba(255, 255, 255, 0.024),
98px 1262px 0 0px rgba(255, 255, 255, 0.896),
1280px 123px 0 0px rgba(255, 255, 255, 0.595),
266px 1183px 0 0px rgba(255, 255, 255, 0.385),
1700px 550px 0 0px rgba(255, 255, 255, 0.837),
1309px 570px 0 0px rgba(255, 255, 255, 0.69),
899px 1246px 0 0px rgba(255, 255, 255, 0.8),
876px 1045px 0 0px rgba(255, 255, 255, 0.796),
264px 496px 0 0px rgba(255, 255, 255, 0.916),
988px 231px 0 0px rgba(255, 255, 255, 0.34),
1142px 409px 0 0px rgba(255, 255, 255, 0.65),
1537px 45px 0 0px rgba(255, 255, 255, 0.761),
607px 21px 0 0px rgba(255, 255, 255, 0.979),
1574px 1738px 0 0px rgba(255, 255, 255, 0.8),
719px 1373px 0 0px rgba(255, 255, 255, 0.447),
362px 347px 0 0px rgba(255, 255, 255, 0.438),
1710px 825px 0 0px rgba(255, 255, 255, 0.636),
1629px 476px 0 0px rgba(255, 255, 255, 0.092),
732px 513px 0 0px rgba(255, 255, 255, 0.381),
1165px 356px 0 0px rgba(255, 255, 255, 0.198),
1px 1186px 0 0px rgba(255, 255, 255, 0.745),
906px 12px 0 0px rgba(255, 255, 255, 0.016),
442px 1632px 0 0px rgba(255, 255, 255, 0.413),
1214px 1362px 0 0px rgba(255, 255, 255, 0.024),
214px 641px 0 0px rgba(255, 255, 255, 0.79),
1438px 1323px 0 0px rgba(255, 255, 255, 0.191),
250px 1156px 0 0px rgba(255, 255, 255, 0.5),
1733px 1251px 0 0px rgba(255, 255, 255, 0.196),
1009px 1416px 0 0px rgba(255, 255, 255, 0.897),
1502px 1094px 0 0px rgba(255, 255, 255, 0.635),
1424px 1424px 0 0px rgba(255, 255, 255, 0.681),
176px 1032px 0 0px rgba(255, 255, 255, 0.278),
649px 133px 0 0px rgba(255, 255, 255, 0.744),
217px 108px 0 0px rgba(255, 255, 255, 0.347),
804px 614px 0 0px rgba(255, 255, 255, 0.859),
1060px 1193px 0 0px rgba(255, 255, 255, 0.687),
1333px 824px 0 0px rgba(255, 255, 255, 0.582),
1394px 1481px 0 0px rgba(255, 255, 255, 0.372),
1730px 1681px 0 0px rgba(255, 255, 255, 0.181),
1302px 173px 0 0px rgba(255, 255, 255, 0.964),
1623px 254px 0 0px rgba(255, 255, 255, 0.651),
1730px 315px 0 0px rgba(255, 255, 255, 0.827),
1733px 1361px 0 0px rgba(255, 255, 255, 0.492),
1767px 133px 0 0px rgba(255, 255, 255, 0.642),
637px 1586px 0 0px rgba(255, 255, 255, 0.635),
1545px 1295px 0 0px rgba(255, 255, 255, 0.106),
1732px 1153px 0 0px rgba(255, 255, 255, 0.595),
125px 674px 0 0px rgba(255, 255, 255, 0.263),
810px 1036px 0 0px rgba(255, 255, 255, 0.281),
1213px 780px 0 0px rgba(255, 255, 255, 0.105),
1253px 402px 0 0px rgba(255, 255, 255, 0.321),
1620px 1747px 0 0px rgba(255, 255, 255, 0.695),
146px 1621px 0 0px rgba(255, 255, 255, 0.214),
416px 113px 0 0px rgba(255, 255, 255, 0.773),
1505px 1750px 0 0px rgba(255, 255, 255, 0.865),
1084px 1790px 0 0px rgba(255, 255, 255, 0.511),
838px 1072px 0 0px rgba(255, 255, 255, 0.12),
552px 1333px 0 0px rgba(255, 255, 255, 0.539),
843px 1660px 0 0px rgba(255, 255, 255, 0.475),
826px 633px 0 0px rgba(255, 255, 255, 0.988),
1682px 623px 0 0px rgba(255, 255, 255, 0.1),
940px 948px 0 0px rgba(255, 255, 255, 0.33),
1731px 686px 0 0px rgba(255, 255, 255, 0.945),
1387px 117px 0 0px rgba(255, 255, 255, 0.368),
1243px 296px 0 0px rgba(255, 255, 255, 0.635),
345px 517px 0 0px rgba(255, 255, 255, 0.274),
56px 567px 0 0px rgba(255, 255, 255, 0.917),
485px 441px 0 0px rgba(255, 255, 255, 0.117),
1612px 97px 0 0px rgba(255, 255, 255, 0.541),
640px 435px 0 0px rgba(255, 255, 255, 0.715),
1145px 214px 0 0px rgba(255, 255, 255, 0.46),
454px 1326px 0 0px rgba(255, 255, 255, 0.477),
927px 258px 0 0px rgba(255, 255, 255, 0.164),
752px 142px 0 0px rgba(255, 255, 255, 0.923),
77px 1254px 0 0px rgba(255, 255, 255, 0.361),
224px 1083px 0 0px rgba(255, 255, 255, 0.303),
401px 1010px 0 0px rgba(255, 255, 255, 0.559),
947px 1657px 0 0px rgba(255, 255, 255, 0.264),
421px 1226px 0 0px rgba(255, 255, 255, 0.074),
1777px 228px 0 0px rgba(255, 255, 255, 0.308),
73px 1681px 0 0px rgba(255, 255, 255, 0.56),
712px 1072px 0 0px rgba(255, 255, 255, 0.414),
1428px 1565px 0 0px rgba(255, 255, 255, 0.773),
59px 532px 0 0px rgba(255, 255, 255, 0.062),
1362px 1105px 0 0px rgba(255, 255, 255, 0.54),
1582px 986px 0 0px rgba(255, 255, 255, 0.724),
508px 624px 0 0px rgba(255, 255, 255, 0.228),
305px 1502px 0 0px rgba(255, 255, 255, 0.787),
1413px 1105px 0 0px rgba(255, 255, 255, 0.397),
1131px 818px 0 0px rgba(255, 255, 255, 0.309),
360px 765px 0 0px rgba(255, 255, 255, 0.157),
1170px 436px 0 0px rgba(255, 255, 255, 0.308),
508px 1489px 0 0px rgba(255, 255, 255, 0.434),
915px 425px 0 0px rgba(255, 255, 255, 0.889),
1523px 966px 0 0px rgba(255, 255, 255, 0.443),
1023px 718px 0 0px rgba(255, 255, 255, 0.856),
616px 1044px 0 0px rgba(255, 255, 255, 0.914),
735px 707px 0 0px rgba(255, 255, 255, 0.787),
1155px 1108px 0 0px rgba(255, 255, 255, 0.847),
198px 196px 0 0px rgba(255, 255, 255, 0.776),
30px 294px 0 0px rgba(255, 255, 255, 0.527),
1133px 1124px 0 0px rgba(255, 255, 255, 0.963),
455px 224px 0 0px rgba(255, 255, 255, 0.216),
658px 695px 0 0px rgba(255, 255, 255, 0.058),
1757px 274px 0 0px rgba(255, 255, 255, 0.081),
165px 671px 0 0px rgba(255, 255, 255, 0.928),
959px 1673px 0 0px rgba(255, 255, 255, 0.741),
859px 1315px 0 0px rgba(255, 255, 255, 0.167),
475px 709px 0 0px rgba(255, 255, 255, 0.154),
805px 1705px 0 0px rgba(255, 255, 255, 0.696),
1208px 653px 0 0px rgba(255, 255, 255, 0.381),
1543px 976px 0 0px rgba(255, 255, 255, 0.388),
153px 798px 0 0px rgba(255, 255, 255, 0.018),
1573px 1032px 0 0px rgba(255, 255, 255, 0.382),
1043px 697px 0 0px rgba(255, 255, 255, 0.294),
360px 1367px 0 0px rgba(255, 255, 255, 0.934),
1307px 1578px 0 0px rgba(255, 255, 255, 0.121),
1783px 1395px 0 0px rgba(255, 255, 255, 0.934),
514px 683px 0 0px rgba(255, 255, 255, 0.276),
1080px 1362px 0 0px rgba(255, 255, 255, 0.424),
1545px 761px 0 0px rgba(255, 255, 255, 0.698),
1408px 1342px 0 0px rgba(255, 255, 255, 0.37),
256px 690px 0 0px rgba(255, 255, 255, 0.118),
309px 438px 0 0px rgba(255, 255, 255, 0.666),
522px 654px 0 0px rgba(255, 255, 255, 0.323),
112px 1787px 0 0px rgba(255, 255, 255, 0.062),
955px 195px 0 0px rgba(255, 255, 255, 0.566),
1481px 1510px 0 0px rgba(255, 255, 255, 0.606),
683px 287px 0 0px rgba(255, 255, 255, 0.25),
757px 41px 0 0px rgba(255, 255, 255, 0.301),
1287px 1426px 0 0px rgba(255, 255, 255, 0.742),
1240px 1757px 0 0px rgba(255, 255, 255, 0.349),
377px 1307px 0 0px rgba(255, 255, 255, 0.509),
39px 6px 0 0px rgba(255, 255, 255, 0.871),
1645px 710px 0 0px rgba(255, 255, 255, 0.879),
997px 1022px 0 0px rgba(255, 255, 255, 0.411),
502px 1590px 0 0px rgba(255, 255, 255, 0.355),
397px 1330px 0 0px rgba(255, 255, 255, 0.922),
1789px 1239px 0 0px rgba(255, 255, 255, 0.275),
1669px 1485px 0 0px rgba(255, 255, 255, 0.826),
1028px 1039px 0 0px rgba(255, 255, 255, 0.724),
1685px 1302px 0 0px rgba(255, 255, 255, 0.794),
1571px 495px 0 0px rgba(255, 255, 255, 0.142),
1243px 1284px 0 0px rgba(255, 255, 255, 0.184),
247px 1030px 0 0px rgba(255, 255, 255, 0.709),
105px 1388px 0 0px rgba(255, 255, 255, 0.979),
1019px 942px 0 0px rgba(255, 255, 255, 0.032),
1745px 756px 0 0px rgba(255, 255, 255, 0.364),
698px 1497px 0 0px rgba(255, 255, 255, 0.949),
403px 57px 0 0px rgba(255, 255, 255, 0.928),
1175px 1107px 0 0px rgba(255, 255, 255, 0.149),
1133px 765px 0 0px rgba(255, 255, 255, 0.061),
931px 401px 0 0px rgba(255, 255, 255, 0.279),
394px 1212px 0 0px rgba(255, 255, 255, 0.352),
1558px 1420px 0 0px rgba(255, 255, 255, 0.102),
455px 181px 0 0px rgba(255, 255, 255, 0.999),
578px 1052px 0 0px rgba(255, 255, 255, 0.168),
94px 275px 0 0px rgba(255, 255, 255, 0.56),
627px 1370px 0 0px rgba(255, 255, 255, 0.543),
1080px 538px 0 0px rgba(255, 255, 255, 0.281),
256px 1128px 0 0px rgba(255, 255, 255, 0.234),
767px 880px 0 0px rgba(255, 255, 255, 0.356),
389px 83px 0 0px rgba(255, 255, 255, 0.696),
1026px 1662px 0 0px rgba(255, 255, 255, 0.979),
1493px 1751px 0 0px rgba(255, 255, 255, 0.344),
27px 157px 0 0px rgba(255, 255, 255, 0.274),
1493px 668px 0 0px rgba(255, 255, 255, 0.974),
1265px 1060px 0 0px rgba(255, 255, 255, 0.61),
1755px 1118px 0 0px rgba(255, 255, 255, 0.103),
776px 997px 0 0px rgba(255, 255, 255, 0.414),
116px 491px 0 0px rgba(255, 255, 255, 0.988),
473px 116px 0 0px rgba(255, 255, 255, 0.019),
770px 705px 0 0px rgba(255, 255, 255, 0.663),
1705px 928px 0 0px rgba(255, 255, 255, 0.09),
1187px 616px 0 0px rgba(255, 255, 255, 0.597),
186px 875px 0 0px rgba(255, 255, 255, 0.422),
1734px 616px 0 0px rgba(255, 255, 255, 0.358),
53px 769px 0 0px rgba(255, 255, 255, 0.78),
1204px 1310px 0 0px rgba(255, 255, 255, 0.91),
1538px 829px 0 0px rgba(255, 255, 255, 0.645),
1354px 1212px 0 0px rgba(255, 255, 255, 0.601),
387px 477px 0 0px rgba(255, 255, 255, 0.688),
1387px 1358px 0 0px rgba(255, 255, 255, 0.427),
1430px 1171px 0 0px rgba(255, 255, 255, 0.956),
1364px 1790px 0 0px rgba(255, 255, 255, 0.156),
517px 825px 0 0px rgba(255, 255, 255, 0.552),
156px 1240px 0 0px rgba(255, 255, 255, 0.698),
980px 86px 0 0px rgba(255, 255, 255, 0.007),
742px 1268px 0 0px rgba(255, 255, 255, 0.683),
1474px 1584px 0 0px rgba(255, 255, 255, 0.296),
1195px 1778px 0 0px rgba(255, 255, 255, 0.784),
1784px 141px 0 0px rgba(255, 255, 255, 0.842),
296px 60px 0 0px rgba(255, 255, 255, 0.835),
1313px 1196px 0 0px rgba(255, 255, 255, 0.821),
1053px 165px 0 0px rgba(255, 255, 255, 0.304),
1672px 1022px 0 0px rgba(255, 255, 255, 0.027),
1612px 153px 0 0px rgba(255, 255, 255, 0.914),
119px 472px 0 0px rgba(255, 255, 255, 0.142),
815px 863px 0 0px rgba(255, 255, 255, 0.276),
1139px 1189px 0 0px rgba(255, 255, 255, 0.24),
1232px 108px 0 0px rgba(255, 255, 255, 0.854),
1254px 1284px 0 0px rgba(255, 255, 255, 0.772),
410px 488px 0 0px rgba(255, 255, 255, 0.405),
1440px 1114px 0 0px rgba(255, 255, 255, 0.425),
681px 1345px 0 0px rgba(255, 255, 255, 0.825),
1634px 1680px 0 0px rgba(255, 255, 255, 0.966),
953px 331px 0 0px rgba(255, 255, 255, 0.093),
1570px 70px 0 0px rgba(255, 255, 255, 0.926),
1507px 1486px 0 0px rgba(255, 255, 255, 0.347),
71px 736px 0 0px rgba(255, 255, 255, 0.987),
1427px 1452px 0 0px rgba(255, 255, 255, 0.183),
413px 1024px 0 0px rgba(255, 255, 255, 0.652),
1668px 1766px 0 0px rgba(255, 255, 255, 0.684),
1744px 1644px 0 0px rgba(255, 255, 255, 0.233),
659px 1295px 0 0px rgba(255, 255, 255, 0.064),
1799px 933px 0 0px rgba(255, 255, 255, 0.029),
1603px 1536px 0 0px rgba(255, 255, 255, 0.348),
654px 1036px 0 0px rgba(255, 255, 255, 0.158),
779px 415px 0 0px rgba(255, 255, 255, 0.744),
1042px 1597px 0 0px rgba(255, 255, 255, 0.023),
1529px 583px 0 0px rgba(255, 255, 255, 0.133),
461px 740px 0 0px rgba(255, 255, 255, 0.879),
1354px 1112px 0 0px rgba(255, 255, 255, 0.236),
573px 1438px 0 0px rgba(255, 255, 255, 0.782),
1147px 886px 0 0px rgba(255, 255, 255, 0.07),
697px 1775px 0 0px rgba(255, 255, 255, 0.938),
1412px 714px 0 0px rgba(255, 255, 255, 0.566),
1497px 1713px 0 0px rgba(255, 255, 255, 0.295),
260px 1775px 0 0px rgba(255, 255, 255, 0.662),
168px 552px 0 0px rgba(255, 255, 255, 0.977),
52px 525px 0 0px rgba(255, 255, 255, 0.938),
1027px 569px 0 0px rgba(255, 255, 255, 0.494),
1333px 1079px 0 0px rgba(255, 255, 255, 0.611),
482px 28px 0 0px rgba(255, 255, 255, 0.603),
409px 360px 0 0px rgba(255, 255, 255, 0.041),
189px 903px 0 0px rgba(255, 255, 255, 0.276),
251px 1443px 0 0px rgba(255, 255, 255, 0.424),
1016px 1267px 0 0px rgba(255, 255, 255, 0.361),
229px 1350px 0 0px rgba(255, 255, 255, 0.6),
29px 1559px 0 0px rgba(255, 255, 255, 0.938),
1018px 1198px 0 0px rgba(255, 255, 255, 0.724),
739px 1391px 0 0px rgba(255, 255, 255, 0.143),
1372px 1433px 0 0px rgba(255, 255, 255, 0.557),
198px 1662px 0 0px rgba(255, 255, 255, 0.307),
1008px 213px 0 0px rgba(255, 255, 255, 0.51),
1548px 376px 0 0px rgba(255, 255, 255, 0.786),
329px 1199px 0 0px rgba(255, 255, 255, 0.364),
929px 862px 0 0px rgba(255, 255, 255, 0.913),
1333px 762px 0 0px rgba(255, 255, 255, 0.936),
18px 700px 0 0px rgba(255, 255, 255, 0.279),
1155px 1003px 0 0px rgba(255, 255, 255, 0.974),
1034px 720px 0 0px rgba(255, 255, 255, 0.484),
597px 463px 0 0px rgba(255, 255, 255, 0.342),
599px 1660px 0 0px rgba(255, 255, 255, 0.946),
655px 482px 0 0px rgba(255, 255, 255, 0.049),
1369px 872px 0 0px rgba(255, 255, 255, 0.678),
341px 1507px 0 0px rgba(255, 255, 255, 0.77),
827px 356px 0 0px rgba(255, 255, 255, 0.543),
911px 1225px 0 0px rgba(255, 255, 255, 0.416),
848px 1452px 0 0px rgba(255, 255, 255, 0.026),
1688px 1264px 0 0px rgba(255, 255, 255, 0.589),
733px 1605px 0 0px rgba(255, 255, 255, 0.088),
767px 590px 0 0px rgba(255, 255, 255, 0.339),
1366px 902px 0 0px rgba(255, 255, 255, 0.681),
614px 1019px 0 0px rgba(255, 255, 255, 0.058),
902px 326px 0 0px rgba(255, 255, 255, 0.384),
1505px 777px 0 0px rgba(255, 255, 255, 0.78),
1132px 868px 0 0px rgba(255, 255, 255, 0.863),
1008px 1047px 0 0px rgba(255, 255, 255, 0.727),
648px 567px 0 0px rgba(255, 255, 255, 0.1),
997px 373px 0 0px rgba(255, 255, 255, 0.18),
409px 608px 0 0px rgba(255, 255, 255, 0.894),
1227px 730px 0 0px rgba(255, 255, 255, 0.27),
257px 1681px 0 0px rgba(255, 255, 255, 0.186),
155px 1244px 0 0px rgba(255, 255, 255, 0.95),
996px 1678px 0 0px rgba(255, 255, 255, 0.105),
1348px 794px 0 0px rgba(255, 255, 255, 0.916),
42px 1092px 0 0px rgba(255, 255, 255, 0.386),
1388px 150px 0 0px rgba(255, 255, 255, 0.358),
1507px 10px 0 0px rgba(255, 255, 255, 0.12),
1342px 1572px 0 0px rgba(255, 255, 255, 0.837),
845px 1168px 0 0px rgba(255, 255, 255, 0.637),
570px 654px 0 0px rgba(255, 255, 255, 0.546),
1387px 824px 0 0px rgba(255, 255, 255, 0.594),
1572px 1306px 0 0px rgba(255, 255, 255, 0.414),
1250px 1772px 0 0px rgba(255, 255, 255, 0.894),
585px 1550px 0 0px rgba(255, 255, 255, 0.275),
524px 1335px 0 0px rgba(255, 255, 255, 0.587),
1684px 317px 0 0px rgba(255, 255, 255, 0.739),
929px 57px 0 0px rgba(255, 255, 255, 0.481),
1749px 1409px 0 0px rgba(255, 255, 255, 0.583),
861px 25px 0 0px rgba(255, 255, 255, 0.377),
927px 615px 0 0px rgba(255, 255, 255, 0.989),
202px 563px 0 0px rgba(255, 255, 255, 0.971),
55px 318px 0 0px rgba(255, 255, 255, 0.931),
1283px 1352px 0 0px rgba(255, 255, 255, 0.785),
1553px 411px 0 0px rgba(255, 255, 255, 0.713),
692px 1140px 0 0px rgba(255, 255, 255, 0.147),
599px 805px 0 0px rgba(255, 255, 255, 0.743),
1687px 588px 0 0px rgba(255, 255, 255, 0.467),
843px 1417px 0 0px rgba(255, 255, 255, 0.44),
704px 447px 0 0px rgba(255, 255, 255, 0.25),
1119px 1489px 0 0px rgba(255, 255, 255, 0.152),
688px 1757px 0 0px rgba(255, 255, 255, 0.813),
176px 1732px 0 0px rgba(255, 255, 255, 0.709),
426px 632px 0 0px rgba(255, 255, 255, 0.115),
1383px 487px 0 0px rgba(255, 255, 255, 0.578),
129px 663px 0 0px rgba(255, 255, 255, 0.883),
1366px 368px 0 0px rgba(255, 255, 255, 0.872),
1029px 988px 0 0px rgba(255, 255, 255, 0.233),
544px 155px 0 0px rgba(255, 255, 255, 0.408),
1611px 529px 0 0px rgba(255, 255, 255, 0.548),
641px 194px 0 0px rgba(255, 255, 255, 0.303),
548px 1354px 0 0px rgba(255, 255, 255, 0.589),
1777px 84px 0 0px rgba(255, 255, 255, 0.906),
100px 343px 0 0px rgba(255, 255, 255, 0.79),
1263px 485px 0 0px rgba(255, 255, 255, 0.005),
1557px 1127px 0 0px rgba(255, 255, 255, 0.242),
1033px 582px 0 0px rgba(255, 255, 255, 0.358),
1554px 1419px 0 0px rgba(255, 255, 255, 0.319),
326px 122px 0 0px rgba(255, 255, 255, 0.332),
317px 1505px 0 0px rgba(255, 255, 255, 0.774),
667px 763px 0 0px rgba(255, 255, 255, 0.15),
1760px 1379px 0 0px rgba(255, 255, 255, 0.706),
346px 1207px 0 0px rgba(255, 255, 255, 0.038),
469px 1663px 0 0px rgba(255, 255, 255, 0.673),
715px 162px 0 0px rgba(255, 255, 255, 0.897),
1749px 1418px 0 0px rgba(255, 255, 255, 0.969),
1309px 1651px 0 0px rgba(255, 255, 255, 0.788),
1723px 1660px 0 0px rgba(255, 255, 255, 0.266),
1457px 1364px 0 0px rgba(255, 255, 255, 0.614),
309px 674px 0 0px rgba(255, 255, 255, 0.309),
1737px 628px 0 0px rgba(255, 255, 255, 0.026),
624px 204px 0 0px rgba(255, 255, 255, 0.897),
1731px 860px 0 0px rgba(255, 255, 255, 0.773),
1487px 508px 0 0px rgba(255, 255, 255, 0.337),
1036px 977px 0 0px rgba(255, 255, 255, 0.335),
1578px 1702px 0 0px rgba(255, 255, 255, 0.885),
1585px 1356px 0 0px rgba(255, 255, 255, 0.769),
922px 1507px 0 0px rgba(255, 255, 255, 0.433),
660px 1219px 0 0px rgba(255, 255, 255, 0.595),
1770px 1513px 0 0px rgba(255, 255, 255, 0.292),
1717px 236px 0 0px rgba(255, 255, 255, 0.533),
566px 16px 0 0px rgba(255, 255, 255, 0.193),
681px 862px 0 0px rgba(255, 255, 255, 0.69),
1304px 1384px 0 0px rgba(255, 255, 255, 0.376),
135px 449px 0 0px rgba(255, 255, 255, 0.532),
1714px 1560px 0 0px rgba(255, 255, 255, 0.339),
1599px 1646px 0 0px rgba(255, 255, 255, 0.262),
680px 566px 0 0px rgba(255, 255, 255, 0.086),
416px 470px 0 0px rgba(255, 255, 255, 0.997),
1665px 1547px 0 0px rgba(255, 255, 255, 0.519),
1246px 373px 0 0px rgba(255, 255, 255, 0.601),
449px 1620px 0 0px rgba(255, 255, 255, 0.184),
256px 217px 0 0px rgba(255, 255, 255, 0.578),
1563px 695px 0 0px rgba(255, 255, 255, 0.92),
1251px 316px 0 0px rgba(255, 255, 255, 0.588),
688px 1367px 0 0px rgba(255, 255, 255, 0.867),
1767px 1030px 0 0px rgba(255, 255, 255, 0.445),
836px 890px 0 0px rgba(255, 255, 255, 0.979),
1075px 148px 0 0px rgba(255, 255, 255, 0.418),
738px 757px 0 0px rgba(255, 255, 255, 0.134),
1228px 1740px 0 0px rgba(255, 255, 255, 0.118),
738px 468px 0 0px rgba(255, 255, 255, 0.976),
603px 810px 0 0px rgba(255, 255, 255, 0.895),
932px 1009px 0 0px rgba(255, 255, 255, 0.797),
1033px 976px 0 0px rgba(255, 255, 255, 0.266),
1332px 1498px 0 0px rgba(255, 255, 255, 0.77),
1732px 851px 0 0px rgba(255, 255, 255, 0.966),
572px 213px 0 0px rgba(255, 255, 255, 0.403),
258px 434px 0 0px rgba(255, 255, 255, 0.189),
1726px 1735px 0 0px rgba(255, 255, 255, 0.026),
519px 662px 0 0px rgba(255, 255, 255, 0.156),
1015px 639px 0 0px rgba(255, 255, 255, 0.904),
833px 927px 0 0px rgba(255, 255, 255, 0.926),
1723px 302px 0 0px rgba(255, 255, 255, 0.461),
334px 855px 0 0px rgba(255, 255, 255, 0.782),
220px 490px 0 0px rgba(255, 255, 255, 0.177),
134px 465px 0 0px rgba(255, 255, 255, 0.922),
586px 1149px 0 0px rgba(255, 255, 255, 0.417),
22px 948px 0 0px rgba(255, 255, 255, 0.448),
192px 69px 0 0px rgba(255, 255, 255, 0.563),
1043px 88px 0 0px rgba(255, 255, 255, 0.371);`;
